import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  registryRights: [],
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "device.serialNumber", name: "global:suppliesmanagement.sn" },
      {
        key: "device.client.name+client.city+client.street+client.houseNumber+client.apartmentNumber",
        name: "global:suppliesmanagement.device.clientName",
      },
    ],
    selectedSearchFields: ["index"],
    dictionaryFields: [],
    url: "suppliesmanagement",
  },
};

const suppliesManagementReducer = createSlice({
  name: "suppliesManagementReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = suppliesManagementReducer.actions;

export default suppliesManagementReducer.reducer;
